<template>
  <main class="page-points page-show-point p-0 d-flex flex-column">
    <ul class="nav nav-pills nav-fill">
      <li class="nav-item">
        <router-link class="nav-link active" :to="{ name: 'points.show' }" aria-current="page">Dati</router-link>
      </li>
      <li class="nav-item">
        <router-link class="nav-link" :to="{ name: 'points.edit' }">Modifica</router-link>
      </li>
      <li class="nav-item">
        <router-link class="nav-link" :to="{ name: 'points.pick' }">Punto</router-link>
      </li>
      <li class="nav-item">
        <router-link class="nav-link" :class="{ 'disabled': !point.hasContours }" :to="{ name: 'points.contours' }">Contorni</router-link>
      </li>
      <li class="nav-item">
        <router-link class="nav-link" :to="{ name: 'points.notes' }">Contenuti</router-link>
      </li>
    </ul>
    <div class="flex-grow-1 container pt-3">
      <div class="table-responsive">
        <table class="table table-condensed">
          <tbody>
          <tr>
            <th>Titolo Finestra</th>
            <td>{{ point.windowTitle }}</td>
          </tr>
          <tr>
            <th>Titolo Particolare</th>
            <td>{{ point.title }}</td>
          </tr>
          <tr>
            <th>Anno</th>
            <td>{{ point.year }}</td>
          </tr>
          <tr>
            <th>Template</th>
            <td>{{ point.templateId | templateId }}</td>
          </tr>
          <tr>
            <th>Colore</th>
            <td>
              <div class="colour-row">
                <ColouredSquare :colour="point.colour | colour" /> <span>{{ point.colour | colourName }}</span>
              </div>
            </td>
          </tr>
          <tr>
            <th>Contorni</th>
            <td>{{ point.hasContours ? 'Attivi' : 'Non attivi' }}</td>
          </tr>
          <tr>
            <th>Livello</th>
            <td>{{ module.name }}</td>
          </tr>
          <tr>
            <th>Progetto</th>
            <td>{{ project.name }}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="mt-3 text-right" v-if="isAdmin">
        <button type="button" @click.prevent="removePoint" class="btn btn-outline-danger mr-2">Cancella</button>
      </div>
    </div>
  </main>
</template>
<script>

import rolesMixin from '@/mixins/roles';
import confirmWithModalMixin from '@/libs/Confirm/mixins/confirmWithModal';
import pageableMixin from '@/libs/Pagination/mixins/pageable';
import templateIdFilter from '@/filters/templateIdFilter';
import colourFilter, { colourNameFilter } from '@/filters/colourFilter';

export default {
  name: 'point-show',
  mixins: [rolesMixin, confirmWithModalMixin, pageableMixin],
  components: {
    ColouredSquare: () => import('@/views/components/Form/Widgets/ColouredSquare'),
  },
  filters: {
    templateId: templateIdFilter,
    colour: colourFilter,
    colourName: colourNameFilter,
  },
  props: {
    project: {
      type: Object,
      required: true,
    },
    module: {
      type: Object,
      required: true,
    },
    point: {
      type: Object,
      required: true,
    },
  },
  methods: {
    removePoint () {
      this.confirm('Cancellare il segnaposto e tutte le note collegate?', 'Sì', 'No')
        .then(confirmed => {
          if (!confirmed) {
            return;
          }

          this.$api.removePoint(this.point.id)
            .then(() => {
              this.$router.replace({ name: 'modules.show' });
            })
            .catch(this.$log.errors)
          ;
        })
      ;
    },
  },
};

</script>

<style lang="scss">

.colour-row {
  display: flex;
  flex-direction: row;
  align-items: center;

  span {
    margin-left: 10px;
  }
}

</style>
